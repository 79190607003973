import Vue from 'vue';
import MoneyFormat from 'vue-money-format';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

// eslint-disable-next-line
import { VueperSlides, VueperSlide } from 'vueperslides';

import {
  AlertPlugin,
  BadgePlugin,
  ButtonGroupPlugin,
  ButtonPlugin,
  CalendarPlugin,
  CardPlugin,
  CollapsePlugin,
  DropdownPlugin,
  FormCheckboxPlugin,
  FormDatepickerPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  InputGroupPlugin,
  JumbotronPlugin,
  LayoutPlugin,
  ModalPlugin,
  NavbarPlugin,
  NavPlugin,
  OverlayPlugin,
  PaginationPlugin,
  PopoverPlugin,
  ProgressPlugin,
  SidebarPlugin,
  SpinnerPlugin,
  TablePlugin,
  TabsPlugin,
  ToastPlugin,
  VBTooltipPlugin,
} from 'bootstrap-vue';

// Homepage
import SfHomeSearch from '../../components/home/HomeSearch.vue';

// Booking
import SfConfigurePlayers from '../../components/booking/Form/ConfigurePlayersForm.vue';
import SfConfigureEntranceForm from '../../components/booking/Form/ConfigureEntranceForm.vue';
import SfFieldRentalForm from '../../components/booking/Form/FieldRentalForm.vue';
import PreferedLanguageType from '../../components/common/Form/PreferedLanguageType.vue';
import SfEntranceSummary from '../../components/booking/Form/EntranceSummary.vue';
import SfEntrance from '../../views/front/Purchase/EntranceView.vue';
import SfWalletTopupSelection from '../../components/booking/Wallet/WalletTopupSelection.vue';

// Center
import SfContactSelection from '../../components/center/contact/Select.vue';
import SfContactSelectionOrCreation from '../../components/center/contact/SelectOrCreate.vue';
import SfCenterWallets from '../../components/center/CenterWallets.vue';

import SfCalendarResourceSelector from '../../components/common/calendar/CalendarResourceSelector.vue';
import SfActivitiesSlider from '../../components/center/ActivitiesSlider.vue';
import SfActivitiesNativeSlider from '../../components/center/ActivitiesNativeSlider.vue';
import SfMatchesSlider from '../../components/match/MatchesSlider.vue';
import MultipleItemsForm from '../../components/common/MultipleItemsForm.vue';

import SfInstallBanner from '../../components/Pwa/InstallBanner.vue';
import MainNavbar from '../../components/common/Navbar.vue';
import GetParamDatepicker from '../../components/center/GetParamDatepicker.vue';
import SfModal from '../../components/common/Modal.vue';
import InfoBox from '../../components/common/infobox/InfoBox.vue';
import InfoBoxToggle from '../../components/common/infobox/InfoBoxToggle.vue';
import StickableButton from '../../components/common/StickableButton.vue';
import EmptyState from '../../components/common/EmptyState.vue';
import CenterMatchesList from '../../components/center/CenterMatchesList.vue';
import SimpleProductCard from '../../components/booking/Activity/SimpleProductCard.vue';

Vue.use(AlertPlugin);
Vue.use(BadgePlugin);
Vue.use(ButtonGroupPlugin);
Vue.use(ButtonPlugin);
Vue.use(CalendarPlugin);
Vue.use(CardPlugin);
Vue.use(CollapsePlugin);
Vue.use(DropdownPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(InputGroupPlugin);
Vue.use(JumbotronPlugin);
Vue.use(LayoutPlugin);
Vue.use(ModalPlugin);
Vue.use(NavbarPlugin);
Vue.use(NavPlugin);
Vue.use(OverlayPlugin);
Vue.use(PaginationPlugin);
Vue.use(PopoverPlugin);
Vue.use(ProgressPlugin);
Vue.use(SidebarPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TablePlugin);
Vue.use(TabsPlugin);
Vue.use(ToastPlugin);
Vue.use(VBTooltipPlugin);

Vue.component('MainNavbar', MainNavbar);
Vue.component('MoneyFormat', MoneyFormat);
Vue.component('MultipleItemsForm', MultipleItemsForm);
Vue.component('PreferedLanguageType', PreferedLanguageType);
Vue.component('SfActivitiesSlider', SfActivitiesSlider);
Vue.component('SfActivitiesNativeSlider', SfActivitiesNativeSlider);
Vue.component('SfCalendarResourceSelector', SfCalendarResourceSelector);
Vue.component('SfCenterWallets', SfCenterWallets);
Vue.component('SfCenterMatchesList', CenterMatchesList);
Vue.component('SfConfigureEntranceForm', SfConfigureEntranceForm);
Vue.component('SfConfigurePlayers', SfConfigurePlayers);
Vue.component('SfContactSelection', SfContactSelection);
Vue.component('SfContactSelectionOrCreation', SfContactSelectionOrCreation);
Vue.component('SfEntrance', SfEntrance);
Vue.component('SfEntranceSummary', SfEntranceSummary);
Vue.component('SfFieldRentalForm', SfFieldRentalForm);
Vue.component('SfHomeSearch', SfHomeSearch);
Vue.component('SfInstallBanner', SfInstallBanner);
Vue.component('SfMatchesSlider', SfMatchesSlider);
Vue.component('SfModal', SfModal);
Vue.component('SfWalletTopupSelection', SfWalletTopupSelection);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('VueperSlide', VueperSlide);
Vue.component('VueperSlides', VueperSlides);
Vue.component('GetParamDatepicker', GetParamDatepicker);
Vue.component('InfoBox', InfoBox);
Vue.component('InfoBoxToggle', InfoBoxToggle);
Vue.component('StickableButton', StickableButton);
Vue.component('EmptyState', EmptyState);
Vue.component('SimpleProductCard', SimpleProductCard);
