<template>
  <div>
    <div class="d-flex justify-content-between px-3">
      <div class="pb-2">
        <b-spinner
          v-if="isLoading"
          small
        />
        <span v-else>
          {{ paginator.count }} match{{ paginator.count > 1 ? 's' : '' }}
          <!-- {{ trans('general.at') }} {{ zoneName }} -->
        </span>
      </div>
    </div>

    <template v-if="matches && matches.length">
      <div class="row">
        <div
          v-for="result in matches"
          :key="result.id"
          class="col-12 col-sm-6 col-xl-4 col-xxl-3 mb-4"
        >
          <match-card
            :purchase="result"
            :center="result.center"
          >
            <template #default>
              <slot></slot>
            </template>
          </match-card>
        </div>
      </div>
    </template>

    <div
      v-else-if="isLoaded && !matches.length && !isLoading && !isLoadingMore"
      id="sf-card-no-matches"
      class="py-6 my-6 text-center"
    >
      <div class="mt-1">{{ trans(`website.search.${targetComponent}.no_matches`) }}...</div>
    </div>

    <div
      v-if="isLoading || isLoadingMore"
      style="min-height: 30vh"
      class="d-flex align-items-center justify-content-center"
    >
      <b-spinner large />
    </div>

    <div class="d-flex justify-content-center py-5">
      <b-button
        v-if="matches && matches.length < paginator.count"
        variant="outline-dark"
        size="lg"
        :disabled="isLoading || isLoadingMore"
        @click="loadMore"
      >
        <b-spinner
          v-if="isLoadingMore"
          small
        />
        {{ trans('general.loadMore') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { getMatches } from '../../services/api/FieldRentalPurchase';
import MatchCard from '../match/card/MatchCard.vue';

export default {
  name: 'MatchesList',
  components: {
    MatchCard,
  },
  props: {
    centerId: { type: [String, Number], required: true },
    partnerId: { type: [String, Number], required: true },
  },
  data() {
    return {
      isLoaded: false,
      isLoading: false,
      isLoadingMore: false,
      matches: [],
      paginator: {
        count: 0,
        page: 1,
        page_count: 0,
        limit: 15,
      },
    };
  },
  mounted() {
    this.loadMatches();
  },
  methods: {
    async handleUpdate() {
      // console.debug('MatchesList.handleUpdate');
      await this.loadMatches();
    },
    async loadMatches(isAppend = false) {
      console.debug('CenterMatchesList.loadMatches');
      let options = {
        // Pagination
        page: this.paginator.page,
        limit: this.paginator.limit,
        center: this.centerId,
        partner: this.partnerId,
        future: 1,
      };

      try {
        this.isLoading = true;
        const response = await getMatches(options);
        const matches = response['hydra:member'].map((matchGroup) => {
          const m = Object.hasOwnProperty.call(matchGroup, 0) ? matchGroup[0] : matchGroup;
          return {
            ...m,
            center: { ...m.center, distance: matchGroup.distance },
            date_from: new Date(m.date_from),
            date_to: new Date(m.date_to),
          };
        });

        this.matches = isAppend ? this.matches.concat(matches) : matches;
        this.paginator.count = response['hydra:totalItems'];
        this.paginator.page_count = Math.ceil(response['hydra:totalItems'] / matches.length);
        this.isLoading = false;
        return response;
      } catch (e) {
        console.error(e);
      }

      this.isLoading = false;
      return undefined;
    },
  },
};
</script>
