<template>
  <b-card class="mb-4">
    <b-overlay :show="isLoading">
      <slot :select-entry="handleSelectPrice" />
    </b-overlay>
  </b-card>
</template>

<script>
import { create } from '../../../services/api/SimpleProductPurchase';
import ToasterErrorHandler from '../../mixins/ToasterErrorHandler';
export default {
  name: 'SimpleProductCard',
  mixins: [ToasterErrorHandler],
  props: {
    productId: { type: String, required: true },
    centerId: { type: String, required: true },
    partnerId: { type: String, required: true },
    price: { type: Object, required: true },
  },
  data() {
    return {
      isLoading: false,
      priceEntry: null,
    };
  },
  methods: {
    async createPurchase() {
      if (this.isLoading) {
        return;
      }

      if (!this.$store.state.user) {
        window.location.href = Routing.generate('app_login', {
          _locale: this.spflocale,
          redirect_after_login: window.location.pathname,
        });
      }

      try {
        this.isLoading = true;
        const response = await create(
          this.productId,
          this.priceEntry,
          this.centerId,
          this.partnerId
        );

        window.location = Routing.generate('website.booking', {
          purchase: response.id,
          _locale: this.spflocale,
        });
      } catch (e) {
        this.toastError(e);
      }
      this.isLoading = false;
    },
    handleSelectPrice(p) {
      console.log('handleSelectPrice', p);
      this.priceEntry = p;
      this.createPurchase();
    },
  },
};
</script>
