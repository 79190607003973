import routes from '../../router/api';
import { sendPostRequest } from './Hydra';

export async function create(productId, priceId, centerId, partnerId) {
  const uri = new URL(routes.simpleProductPurchase.all, window.location.origin);
  uri.search = new URLSearchParams({ center: centerId, partner: partnerId });
  const body = {
    // product: { id: productId },
    product: `/api/products/${productId}`,
    priceEntry: { id: priceId },
  };
  return sendPostRequest(uri, body);
}
